<template>
  <div>
    <div class="relative">
      <svg xmlns="http://www.w3.org/2000/svg" width="4" height="17" fill="none" @click.stop="showOptions = !showOptions" class="cursor-pointer">
        <path
          d="M2 4.91602c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2C0 10.016.9 10.916 2 10.916s2-.9 2-1.99998c0-1.1-.9-2-2-2ZM2 12.916c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
          fill="#6B6873"
        />
      </svg>
      <transition name="slideUp">
        <div class="options-card" :style="{ 'min-width': width }" v-click-outside="handleOptionsOutside" v-if="showOptions">
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      width: {
        type: String,
        default: "170px",
        required: false,
      },
    },
    data: () => ({
      options: {},
      showOptions: false,
    }),
    methods: {
      handleOptionsOutside() {
        this.showOptions = false;
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";

  .options-card {
    background: #ffffff;
    /* card/grey 0.5 */
    border: 1px solid #ebeaee;
    border-radius: 4px;
    /* height: 100%; */
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 10;
  }

  .option {
    padding: 18px 15px;
    font-size: 13px;
    color: #1e1d20;
    border-bottom: 1px solid #ebeaee;
  }
  .option .line {
    height: 1px;
    background: #ebeaee;
    width: 100%;
  }
</style>
