var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative" },
      [
        _c(
          "svg",
          {
            staticClass: "cursor-pointer",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "4",
              height: "17",
              fill: "none",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.showOptions = !_vm.showOptions
              },
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M2 4.91602c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm0 2c-1.1 0-2 .9-2 2C0 10.016.9 10.916 2 10.916s2-.9 2-1.99998c0-1.1-.9-2-2-2ZM2 12.916c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z",
                fill: "#6B6873",
              },
            }),
          ]
        ),
        _c("transition", { attrs: { name: "slideUp" } }, [
          _vm.showOptions
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.handleOptionsOutside,
                      expression: "handleOptionsOutside",
                    },
                  ],
                  staticClass: "options-card",
                  style: { "min-width": _vm.width },
                },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }